import removeUndefinedKeys from '~/app/lib/utils/removeUndefinedKeys';
import { toItemPagePath } from '~/app/lib/router2/utils';
import { SongwhipCustomPage } from '../types';
import { toTimestamp } from '../../utils/date';
import { ItemTypes } from '~/types';

import { MappedCustomPage } from './types';
import artistMapper from './mapArtist';

const mapCustomPage = (payload: SongwhipCustomPage) =>
  removeUndefinedKeys<MappedCustomPage>({
    type: ItemTypes.CUSTOM_PAGE,
    id: payload.id,
    path: payload.path,
    pagePath: toItemPagePath(payload.path),
    name: payload.name,
    image: payload.image || undefined,

    // handle mapping serialized CustomPage that don't have artists defined
    // (eg. in edgeWorker responding to on-record-change hook)
    artists: payload.artists?.map(artistMapper),

    createdAtTimestamp: toTimestamp(payload.createdAt),
    updatedAtTimestamp: toTimestamp(payload.updatedAt),

    config: payload.config || undefined,
    ownedByAccounts: payload.ownedByAccounts,
    customLinks: payload.customLinks,
    isDraft: payload.isDraft,

    // REVIEW: These hardcoded props are for compat with artist/album/track items
    links: undefined,
    linksCountries: undefined,
  });

export default mapCustomPage;
